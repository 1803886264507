<template>
  <div class="wechatPay">
    <div class="steps"></div>
    <h3>03 付款预约 </h3>
    <div class="courseBox">
      <img src="@/assets/img/coursePic.png" class="coursePic">
      <div class="info">
        <h4>{{payData.productName}}</h4>
        <p class="price">
          <span class="oldP">¥499</span>
          <span class="newP">¥{{payData.total}}</span>
        </p>
        <p class="address">西直门南大街 成铭大厦 C座23层闻涛运动康复诊所</p>
        <p class="snCode">订单号：{{payData.sn}}</p>
      </div>
    </div>

    <div class="userInfo">
      <h4>确认预约信息</h4>
      <div class="userInfoDet">
        <span class="title">姓名：</span>
        <span>{{payData.name}}</span>
      </div>
      <div class="userInfoDet">
        <span class="title">性别：</span>
        <span>{{payData.genger == 0 ? '女' : '男'}}</span>
      </div>
      <div class="userInfoDet">
        <span class="title">年龄：</span>
        <span v-if="payData.birthday">{{new Date().getFullYear() - payData.birthday.substring(0,4)}}</span>
      </div>
      <div class="userInfoDet">
        <span class="title">联系电话：</span>
        <span>{{payData.mobile}}</span>
      </div>
      <div class="userInfoDet">
        <span class="title">预约时间：</span>
        <span v-if="payData.assessTime == '-1'">待定</span>
        <span v-else>{{payData.assessDate}} {{payData.assessTime == '0' ? '上午' : '下午'}}</span>
      </div>
    </div>

    <div class="operate">
      <van-button block type="text" @click="cancle">取消订单</van-button>
      <van-button block type="info" @click="wechatPayFunction()">确 认</van-button>
    </div>
    <!-- <dl class="content" style="border-bottom:none;">
      <dd id="btn-dd" style="width:100%;">
        <span class="new-btn-login-sp">
          <van-button block type="info" @click="wechatPayFunction()">确 认</van-button>
          <van-button block type="info" @click="cancle">取 消</van-button>
        </span>
      </dd>
    </dl> -->
  </div>
</template>
<script>
import { wechatPay, wechatNotify, canclePay } from "@/api/getData"
import wx from 'weixin-js-sdk'
export default {
  name: "Card",
  data() {
    return {
      payData: {}
    }
  },
  methods: {
    wechatPayment() {
      let data = {
        productCode: this.$route.query.productCode,//that.$route.query.productCode
        sn: this.$route.query.sn,//that.$route.query.sn
      }
      wechatPay(data).then(res => {
        this.payData = res.data;
        if (this.payData.isPay) {
          // this.$toast.fail(this.payData.msg);
          this.$router.push("/my")
        }
      })
    },
    cancle() {
      this.$dialog.confirm({
        title: '是否取消支付',
      })
        .then(() => {
          canclePay(this.$route.query.scheduleId).then(res => {
            this.$toast.success('取消成功');
            this.$router.push('/my')
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    // 微信公众号内微信支付方法
    async wechatPayFunction() {
      let that = this;
      // 配置config信息
      wx.config({
        debug: false,
        appId: that.payData.start.appId, // 必填，公众号的唯一标识
        timestamp: that.payData.start.timestamp, // 必填，生成签名的时间戳
        nonceStr: that.payData.start.nonceStr, // 必填，生成签名的随机串
        signature: that.payData.start.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
      });

      // 通过ready接口处理成功验证
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['chooseWXPay'],
          success: function (res) {
            console.log('微信config检验成功', res);
          }
        });

        wx.chooseWXPay({
          timestamp: that.payData.start.timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr: that.payData.start.nonceStr, // 支付签名随机串，不长于 32 位
          package: that.payData.pay.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: that.payData.pay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: that.payData.pay.paySign, // 支付签名
          success: function (res) {  // 支付成功后的回调函数
            that.$toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration: 0
            });
            let notifyData = {
              paymentId: that.payData.paymentId,
              timestamp: that.payData.start.timestamp,
              nonceStr: that.payData.start.nonceStr,
              packageStr: that.payData.pay.packageStr,
              paySign: that.payData.pay.paySign
            };
            let timerIndex = 0;
            let timerVal = setInterval(() => {
              wechatNotify(notifyData).then(res => {
                if (res.data) {
                  clearInterval(timerVal)
                  that.$toast.loading().clear();
                  that.$toast.success('支付成功');
                  that.$router.push({
                    path: "/card",
                    query: {
                      scheduleId: that.$route.query.scheduleId
                    }
                  })
                }
              })
              timerIndex++;
              //超过三次还未成功 则跳到我的健康页面
              if (timerIndex == 3) {
                clearInterval(timerVal)
                that.$router.push("/my")
              }
            }, 5000);
          },
          fail: function (res) {
          }
        });

      });
    }
  },
  mounted() {
    this.wechatPayment();
  }
}
</script>

<style lang="scss" scoped>
.wechatPay {
  .steps {
    height: 0.06rem;
    background: #24bbd3;
    width: 100%;
  }

  h3 {
    font-size: 0.18rem;
    line-height: 1;
    font-weight: 500;
    margin: 0.36rem 0.16rem;
  }
  .courseBox {
    margin: 0 0.16rem;
    padding: 0.22rem 0.16rem;
    border-radius: 0.08rem;
    background: #ffffff;
    box-shadow: 3px 4px 5px 0px rgba(255, 255, 255, 0.3),
      0px 3px 5px 3px rgba(50, 111, 121, 0.05),
      0px 0px 12px 0px rgba(112, 214, 231, 0.08);
    display: flex;
    justify-content: space-between;

    .coursePic {
      display: block;
      width: 0.9rem;
      height: 0.9rem;
    }
    .info {
      width: 2.04rem;
      h4 {
        font-size: 0.18rem;
        line-height: 1;
        font-weight: bold;
      }
      .price {
        font-size: 0.18rem;
        line-height: 0.32rem;
        margin-top: 0.12rem;
        font-weight: bold;
        .oldP {
          color: rgba(250, 81, 81, 0.3);
          text-decoration: line-through;
        }
        .newP {
          color: #24bbd3;
          margin-left: 0.1rem;
        }
      }
      .address,
      .snCode {
        font-size: 0.14rem;
        line-height: 0.24rem;
        color: #a8a8a8;
        margin-top: 0.08rem;
      }
    }
  }
  .userInfo {
    margin: 0.16rem;
    padding: 0.22rem 0.16rem;
    border-radius: 0.08rem;
    background: #ffffff;
    box-shadow: 3px 4px 5px 0px rgba(255, 255, 255, 0.3),
      0px 3px 5px 3px rgba(50, 111, 121, 0.05),
      0px 0px 12px 0px rgba(112, 214, 231, 0.08);
    h4 {
      font-size: 0.18rem;
      line-height: 1;
      margin: 0 0 0.22rem;
    }
    .userInfoDet {
      height: 0.48rem;
      line-height: 0.48rem;
      padding: 0 0.16rem;
      .title {
        width: 0.8rem;
        color: #a8a8a8;
        display: inline-block;
      }
    }
  }
  .operate {
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    background: #ffffff;
    box-shadow: 3px 4px 5px 0px rgba(255, 255, 255, 0.3),
      0px 3px 5px 3px rgba(50, 111, 121, 0.05),
      0px 0px 12px 0px rgba(112, 214, 231, 0.08);
    padding: 0.14rem 0;
    .van-button--info {
      width: 0.95rem !important;
      margin-right: 0.16rem;
      font-size: 0.14rem !important;
      height: 0.4rem;
    }
    .van-button--text {
      width: auto !important;
      background: none !important;
      border: none !important;
      font-size: 0.14rem !important;
      color: #a8a8a8;
      margin-left: 0.16rem;
    }
  }
}
</style>